body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{
  font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
}
.App {
  text-align: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/back.c05a9308.jpg);
  background-attachment: fixed;
  background-position: center;
  
}

.header{
  width:100vw;
}

.App-nav {
  -webkit-animation: init 1s linear;
          animation: init 1s linear;
  position: absolute;
  
}

.App-nav .wrapper{
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-top:10px;
    width:95vw;
    -webkit-align-items:center;
            align-items:center;
}

.App-nav a{
  color: #E1E1E1;
  text-decoration: none;

}

.link{
  font-family: 'Reem Kufi', sans-serif;
  font-size: 1.2em;
  margin-right: 20px;
}

.active{
  font-weight: 800;
  color:#fff;
}

.App-nav a:hover , button:hover{
  color: #fff;
}

button{
    background-color: transparent;
    padding: 10px;
    border: 0;
    color: #E1E1E1;
}

.App-nav a i {
  padding: 10px;
  font-size: 1.8em;

}
button i {
  font-size: 2.6em;
  

}

.blacky{
  
  background-color: rgba(0,0,0,0.6);
}

.App-main {
  clear:both;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 100vh;
  color: white;
  -webkit-transition: opacity 1s ease-out;
  transition: opacity 1s ease-out;
  -webkit-animation: init 1s linear;
          animation: init 1s linear;
}

.App-link {
  color: #61dafb;
}

.circular-picture{
  height: auto;
  display: block;
  border-radius: 50%;
  position: relative;
  width: 100px;
  border: 2px solid #212020;
  margin-top: 1%;
}

h1{

    font-family: 'Reem Kufi', sans-serif;
    font-size: 3.75em;
    font-weight: 600;
    margin:0;
}

h2{
    font-size: 1.5em;
    font-weight: 100;
    margin:0;
}

.about{
                  margin-top: 75px;

}

.tech{
  margin: 30px 0;
}

.section{
                padding: 15px;
                border-radius: 4px;
                border: 1px solid #3a3c48;
                box-shadow: 1px 1px 10px 1px #000;
                max-width: 70vw;
                min-width: 70vw;
                background-color: rgba(0,0,0,0.4);
}

.section p{
  font-size: 1.1rem;
  color: #b3b3b3;
}

.section h2{
    font-family: 'Reem Kufi', sans-serif;
    font-weight: 600;
    color: #946821;
    border-bottom: 2px solid #73551f;
    text-shadow: 1px 1px #312b27;
    font-size: 1.5em;
}

.float-left{
  float:left;
}

.float-right{
  float:right;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.container{
  display: grid;
  grid-template-columns: 10% auto 10%;
  grid-column-gap: 10px;
  grid-row-gap: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

}

.container div{
  -webkit-align-self: center;
          align-self: center;
}

.row-1{
  grid-row-start: 1;
}

.row-2{
  grid-row-start: 2;
}
.row-3{
  grid-row-start: 3;
}
.col-1{
   grid-column: 1 ;
}
.col-2{
grid-column: 2 ;
}
.col-3{
   grid-column: 3;
   align-self: baseline;
}

.col-3 i{
  font-size: 2.25em;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes init {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes init {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media only screen and (max-width: 650px) {
    .section p{
      font-size: 0.7rem;
    }
    .circular-picture{
      width: 30%;
      
    }
  .link{
    margin: 0;

  }
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .App {
    background-image: url(/static/media/bigback.e300b1c8.jpg);
    background-size: cover;

  }
    .App-main {
    min-height: 100vh;
  }

}

/* Portrait */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
      .App {
    background-image: url(/static/media/bigback.e300b1c8.jpg);
    background-size: cover; 
  }
  .App-main {
    min-height: 100vh;
  }
}

/* Landscape */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
      .App {
    background-image: url(/static/media/bigback.e300b1c8.jpg);
    background-size: cover;
  }
        .App-main {
    min-height: 100vh;
  }
}

@media only screen and (min-width: 1900px) {
      .App {
    background-image: url(/static/media/bigback.e300b1c8.jpg);
    background-size: cover;
  }
  .App-main {
    min-height: 100vh;
  }
}





